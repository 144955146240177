import React, { useState, useEffect } from 'react';
import utils from '../Utils/MathUtils';
import PlayNumber from './PlayNumber';
import PlayAgain from './PlayAgain';
import StarsDisplay from './StarsDisplay';


// custom hooks
const useGameState = () =>
{
    const numbers = 9;
    const [stars, setStars] = useState(utils.random(1,9));
    const [availableNumbers, setAvailableNumbers] = useState(utils.range(1,numbers));
    const [candidateNumbers, setCandidateNumbers] = useState([]);
    const [secondsLeft, setSecondsLeft] = useState(10);

    useEffect(() =>
    {
        if (secondsLeft > 0 && availableNumbers.length > 0)
        {
            const timerId = setTimeout(() =>
            {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);

            return () =>
            {
                clearTimeout(timerId);
            }
        }
    });

    const setGameState = (newCandidateNumbers) =>
    {
        if (utils.sum(newCandidateNumbers) !== stars)
        {
            setCandidateNumbers(newCandidateNumbers);
        }
        else
        {
            const newAvailableNumbers = availableNumbers.filter(n => !newCandidateNumbers.includes(n));
            setStars(utils.randomSumIn(newAvailableNumbers, numbers));
            setAvailableNumbers(newAvailableNumbers);
            setCandidateNumbers([]);
        }
    };

    return {stars, availableNumbers, candidateNumbers, secondsLeft, setGameState};
};

const Game = (props) =>
{
    // useState returns the getter (the state obj) and setter (updater function)
    const numbers = 9;
    const {stars, availableNumbers, candidateNumbers, secondsLeft, setGameState} = useGameState();
    const candidatesAreWrong = utils.sum(candidateNumbers) > stars;
    const gameStatus  = availableNumbers.length === 0 ? "won"
                        : secondsLeft === 0 ? "lost" : "active";


    const onNumberClick = (number, currentStatus) =>
    {
        if (gameStatus !== 'active' || currentStatus === "used")
        {
            return;
        }

        const newCandidateNumbers = currentStatus === "available"
                                    ? candidateNumbers.concat(number)
                                    : candidateNumbers.filter(cn => cn !== number);

        setGameState(newCandidateNumbers);
    };

    const numberStatus = (number) =>
    {
        // used
        if(!availableNumbers.includes(number))
        {
            return 'used';
        }

        if(candidateNumbers.includes(number))
        {
            return candidatesAreWrong ? 'wrong' : 'candidate';
        }

        return 'available';
    };

    return (
        <div className="game">
            <h1>Star Match Game</h1>
        <div className="help">
            Pick 1 or more numbers that sum to the number of stars
        </div>

        <div className="body">

            <div className="left">
            {
                gameStatus !== "active" ? <PlayAgain onClickRef={props.starNewGameRef} gameStatus={gameStatus} />
                                        : <StarsDisplay count={stars} />
            }
            </div>

            <div className="right">
            {utils.range(1,numbers).map(number =>
                <PlayNumber
                    key={number}
                    number={number}

                    // parent telling child what method to invoke!
                    onClick={onNumberClick}

                    status={numberStatus(number)} />
            )}
            </div>

        </div>

        <div className="timer">Time Remaining: {secondsLeft}</div>
        </div>
    );
};

export default Game;
