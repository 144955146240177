import React, { useState } from 'react';
import Game from './Game';


const StarMatch = () =>
{
    const [gameId, setGameId] = useState(1);

    console.log(`gameId: ${gameId}`);
    return (
            <Game key={gameId}
                    starNewGameRef={() =>
                        {
                            setGameId(gameId+1);
                        }
                    }
            />);
};


export default StarMatch;